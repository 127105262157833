<template>
  <div>
<!--    <Header></Header>-->
<!--    <BGImg ></BGImg>-->
<!--    <div style="padding: 50px;">-->
<!--      <TinyMCE v-model="msg"-->
<!--               v-on:submitSuccess="submitSuccess"-->
<!--               :edit_bar_show="true"-->
<!--               ref="editor">-->
<!--      </TinyMCE>-->
<!--    </div>-->
    <VideoItem></VideoItem>
<!--    <Footer></Footer>-->
  </div>
</template>

<script>
import HomeDocList from "@/components/Home/HomeDocList";
import HomeCarousel from "@/components/Home/HomeCarousel";
import Header from "@/components/Header";
import HomeCard from "@/components/Home/HomeCard";
import Footer from "@/components/Footer";
import BGImg from "@/components/BGImg";
import HomeFooter from "@/components/Home/HomeFooter";
import DocList from "@/views/DocList";
import Document from "@/views/Document";
import TinyMCE from "@/components/TinyMCE";
import VideoItem from "@/components/VideoItem";
export default {
  name: "testdoc",
  components: {
    VideoItem,
    TinyMCE, Document, DocList, HomeFooter, BGImg, Footer, HomeCard, Header, HomeCarousel, HomeDocList},
  data(){
    return{
      msg:"<h3><a name=\"_Toc21827\"></a>1.1.1&nbsp;<span style=\"font-family: 黑体;\">项目运行页面</span></h3>\n" +
          "<p><span style=\"font-family: 宋体;\">项目运行主要包括项目的配置选择以及进入项目之后的</span>JupyterLab<span style=\"font-family: 宋体;\">页面和</span><span style=\"font-family: Times New Roman;\">TensorBoard</span><span style=\"font-family: 宋体;\">页面，如</span><span style=\"font-family: 宋体;\">图</span> 5.8<span style=\"font-family: 宋体;\">、</span><span style=\"font-family: 宋体;\">图</span> 5.9<span style=\"font-family: 宋体;\">所示。</span></p>\n" +
          "<p><span style=\"font-family: 宋体;\">图</span> 5.8<span style=\"font-family: 宋体;\">运行项目</span>&mdash;&mdash;JupyterLab<span style=\"font-family: 宋体;\">界面</span></p>",

    }
  },
  methods:{
    submitSuccess() {
      this.disabled = true
    },
    submit(){
      console.log(this.msg)
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>