<template>
<div>
  <Header></Header>
  <BGImg></BGImg>
  <div v-if="loading=== false">
    <div class="container_right">
      <div class="title">
        {{doc.title}}
      </div>
      <div class="detail_container">
<!--        <span class="detail">分类：{{ doc.category.title }}</span>-->
        <span class="detail">来源：{{doc.editor}}</span>
        <span class="detail">发布：{{doc.pub_date.slice(0,10)}} </span>
      </div>
    </div>
    <div style="max-width: 900px;margin: auto">
      <TinyMCE v-model="doc.body_content" :edit_bar_show="false"></TinyMCE>
    </div>
  </div>
  <el-skeleton v-else style="max-width: 900px;margin: 50px auto 150px auto" :rows="8" animated />

  <Footer></Footer>
</div>
</template>

<script>
import TinyMCE from "@/components/TinyMCE";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import BGImg from "@/components/BGImg";
export default {
  name: "Document",
  components: {BGImg, Footer, Header, TinyMCE},
  data(){
    return {
      doc:{
        articleId: this.$route.query.id,
        title:null,
        editor:null,
        views:0,
        pub_date:"",
        category:'',
        body:null,
        body_content:null,
      },
      loading:true,
      htmData:123,
    }
  },
  mounted(){
    // todo 根据参数读取document
    this.axios.get('/api/document/user/article/view/'+this.doc.articleId).then(res=>{
      this.loading = false
      this.doc = res.data
    })
  }
}
</script>

<style scoped>
.container_right{
  max-width: 1200px;
  border-bottom: 1px solid rgb(235, 239, 242);
  padding: 20px 15px 15px;
  margin: 25px auto;
  box-sizing: border-box;
}
.title{
  font-size: 22px;
  font-weight: bolder;
  text-align: center;
  box-sizing: border-box;
}
.detail_container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: rgb(102, 102, 102);
  box-sizing: border-box;
}
.detail{
  margin: 15px;
  font-size: 12px;
}
</style>
