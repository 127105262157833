<template>
<div class="container">
  <div style="width: 270px;height: 160px;">
    <img :src="item.cover" style="width: 100%;height: 100%;object-fit: cover"/>
  </div>
  <div style="text-align: left;margin-top: 10px;">
    {{item.title}}
  </div>
</div>
</template>

<script>
export default {
  name: "VideoItem",
  props:{
    item:{}
  }
}
</script>

<style scoped>
.container{
  margin: 10px 20px;
  width: 270px;
}
</style>